import { combineReducers } from "redux";

/* eslint-disable-next-line*/ // bug for alias with @
import dictionary from "@lumio/core/common/redux/dictionary";
import inventoryMedia from "../../../lib/redux/inventory-media";

export default function createReducer(asyncReducers) {
    return combineReducers({
        dictionary,
        inventoryMedia,
        ...asyncReducers,
    });
}
