import React, { useState, useEffect, useContext } from "react";
import isEmpty from "lodash/isEmpty";
import Context from "../Context";
import Contents from "./Contents";
import FilterContext from "./FilterContext";
import Loading from "@lumio/components/Loading";

const ContentList = ({ className, ...props }) => {
    const filter = useContext(FilterContext);
    const { inventory, actions, inventoryMedia } = useContext(Context);
    const { content = {}, tag = [] } = inventory.data || {};
    const { inventoryListMedia } = actions;
    const [contentList, setContentList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!isEmpty(content) || !isEmpty(tag)) {
            const { _id } = inventory;
            inventoryListMedia(_id);
        } else {
            setContentList([]);
            setLoading(false);
        }
    }, [inventory]);

    useEffect(() => {
        if (inventoryMedia.hasOwnProperty("list_media")) {
            const list = isEmpty(filter)
                ? inventoryMedia.list_media.list
                : inventoryMedia.list_media.list.filter((item) => {
                      if (
                          item.title
                              .toLowerCase()
                              .includes(filter.toLowerCase())
                      ) {
                          return true;
                      }
                      return false;
                  });
            const contentList = list.map((media) => {
                let typeString;
                switch (true) {
                    case media.mime === "lumio/url":
                        typeString = "link";
                        break;
                    case media.mime.includes("image"):
                        typeString = "image";
                        break;
                    case media.mime.includes("video"):
                        typeString = "video";
                        break;
                    case media.mime.includes("audio"):
                        typeString = "audio";
                        break;
                    default:
                        break;
                }
                return {
                    type: "media",
                    data: { ...media, media_type: typeString },
                };
            });
            setContentList(contentList);
            setLoading(false);
        } else {
            setContentList([]);
        }
    }, [inventoryMedia, filter]);

    if (loading) {
        return <Loading />;
    }

    if (isEmpty(contentList)) {
        return "";
    }

    return (
        <Contents className={className} contentList={contentList} {...props} />
    );
};

export default ContentList;
