import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import BaseHeader from "@lumio/components/Header";
import { makeStyles } from "@material-ui/styles";
import { Tabs, Tab } from "@material-ui/core";
import { isEmpty } from "lodash";
import cs from "classnames";

import Title from "./Title";
import ActionButton from "./ActionButton";
import InventoryContext from "../InventoryContext";

const style = makeStyles((theme) => ({
    baseHeader: {
        backgroundColor: theme.custom.colors.primaryShade3,
        boxShadow: "none",
    },
}));

const tabsStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.custom.colors.primaryShade3,
    },
    indicator: {
        height: 4,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        backgroundColor: theme.palette.primary.main,
    },
}));
const tabItemStyles = makeStyles((theme) => ({
    root: {
        textTransform: "initial",
        marginLeft: 2,
        marginRight: 2,
        minWidth: 0,
        [theme.breakpoints.up("md")]: {
            minWidth: 0,
        },
    },
}));

const Header = ({ history, shouldRenderDevice, shouldRenderContent }) => {
    const inventory = useContext(InventoryContext);
    const { data = { device: [], content: [], tag: [] } } = inventory || {};
    const { device, content, tag } = data;
    const {
        location: { pathname },
    } = history;
    let defaultPath = "/";
    if (shouldRenderDevice) {
        defaultPath = "/devices";
    } else if (shouldRenderContent) {
        defaultPath = "/contents";
    }
    const [nav, setNav] = useState(pathname === "/" ? defaultPath : pathname);
    const [tabVal, setTabValue] = useState(defaultPath);
    const useStyle = style();

    const tabsStyle = {
        minWidth: 0,
        margin: "0 2px",
    };

    const handleChange = (e, val) => {
        setNav(val);
    };
    useEffect(() => {
        const split = nav.split("/");
        setTabValue(`/${split[1]}`);
        if (pathname === nav) {
            return;
        }
        history.push(nav);
    }, [nav]);
    return (
        <BaseHeader
            ActionButtonComponent={ActionButton}
            customBackground={useStyle.baseHeader}
            pressToTop
            TitleComponent={Title}
            style={{
                boxShadow: "none",
            }}
        >
            <Tabs classes={tabsStyles()} onChange={handleChange} value={tabVal}>
                {shouldRenderDevice && (
                    <Tab label="Devices" style={tabsStyle} value="/devices" />
                )}
                {shouldRenderContent && (
                    <Tab label="Contents" style={tabsStyle} value="/contents" />
                )}

                {/* { shouldRenderDevice && shouldRenderContent && <Tab label="Devices" style={tabsStyle} value="/devices" /> }
                { shouldRenderDevice && shouldRenderContent && <Tab label="Contents" style={tabsStyle} value="/contents" /> }
                {
                    shouldRenderDevice && !shouldRenderContent &&
                    <Tab label="Devices" style={tabsStyle} value="/devices" />
                }
                {
                    !shouldRenderDevice && shouldRenderContent &&
                    <Tab label="Contents" style={tabsStyle} value="/contents" />
                } */}
            </Tabs>
        </BaseHeader>
    );
};

export default withRouter(Header);
