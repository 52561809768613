import merge from "lodash/merge";
import isEmpty from "lodash/isEmpty";
import keys from "lodash/keys";

import {
    INVENTORY_LIST_MEDIA_FETCHING,
    INVENTORY_LIST_MEDIA_FETCHED,
    // LOOKUP_SINGLE_FETCHED,
    // LOOKUP_MULTI_FETCHED,
    // LOOKUP_MULTI_TAG_FETCHED,
} from "./action";

const defaultState = {
    isFetching: false,
};

// const multiTags = (state, action) => {
//     const {result} = action;
//     const resultKeys = keys(result);
//     const newResult = {};
//     resultKeys.forEach((key) => {
//         if (!isEmpty(result[key])) {
//             newResult[key] = result[key];
//         }
//     });
//     return {...merge(newResult, state), isFetching: false};
// };

export default function (state = defaultState, action) {
    // const {
    //     inventory_id, type, result,
    //     entity_oid,
    // } = action;
    const { type, result } = action;
    // const items = state[entity_type] || {};
    // const { result } = action;
    switch (type) {
        case INVENTORY_LIST_MEDIA_FETCHING:
            return { ...state, isFetching: true };
        case INVENTORY_LIST_MEDIA_FETCHED:
            return {
                ...state,
                list_media: { ...result },
                isFetching: false,
            };
        // case INVENTORY_LIST_MEDIA:
        //     return { ...state };

        // case LOOKUP_FETCHING:
        //     return { ...state, isFetching: true };
        // case LOOKUP_SINGLE_FETCHED:
        //     return {
        //         ...state,
        //         [entity_type]: { ...items, [entity_oid]: result },
        //         isFetching: false,
        //     };
        // case LOOKUP_MULTI_FETCHED:
        //     return {
        //         ...state,
        //         [entity_type]: { ...items, ...result },
        //         isFetching: false,
        //     };
        // case LOOKUP_MULTI_TAG_FETCHED:
        //     return multiTags(state, action);
        default:
            return state;
    }
}
