import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import configureStore from "@lumio/core/common/redux";
import { Context } from "@lumio/core/common/redux/context";

import createReducer from "./reducers";
import App from "./App";

const store = configureStore(createReducer);

const Main = (props) => {
    return (
        <ReduxProvider store={store}>
            <Context.Provider value={{store}}>
                <App {...props} />
            </Context.Provider>
        </ReduxProvider>
    );
};

export default Main;
