import isEmpty from "lodash/isEmpty";

const SINGLE_URL = "/rpc/lookup/single";
const MULTI_URL = "/rpc/lookup/multi";
const MULTI_TAG_URL = "/rpc/lookup/multi_by_tag";

const INVENTORY_LIST_MEDIA_URL = "/inventory/inventory/list_media";

export const INVENTORY_LIST_MEDIA_FETCHING = "INVENTORY_LIST_MEDIA_FETCHING";
export const INVENTORY_LIST_MEDIA_FETCHED = "INVENTORY_LIST_MEDIA_FETCHED";
// export const INVENTORY_LIST_MEDIA = "INVENTORY_LIST_MEDIA";

// export const LOOKUP_MULTI_FETCHED = "LOOKUP_MULTI_FETCHED";
// export const LOOKUP_MULTI_TAG_FETCHED = "LOOKUP_MULTI_TAG_FETCHED";

const actions = (apiClient) => ({
    listMedia: (inventory_id) => async (dispatch, getState) => {
        // const { list_media } = getState();

        dispatch({ type: INVENTORY_LIST_MEDIA_FETCHING });
        const { body } = await apiClient.send("get", INVENTORY_LIST_MEDIA_URL, {
            inventory_id,
            per_page: 0,
        });
        dispatch({
            type: INVENTORY_LIST_MEDIA_FETCHED,
            result: body,
        });
    },
    // single: (entity_oid, entity_type) => async (dispatch, getState) => {
    //     const { dictionary } = getState();
    //     const items = dictionary[entity_type] || [];
    //     if (items[entity_oid]) {
    //         return;
    //     }
    //     dispatch({ type: LOOKUP_FETCHING });
    //     const { body } = await apiClient.send("get", SINGLE_URL, {
    //         entity_oid,
    //         entity_type,
    //     });
    //     dispatch({
    //         type: LOOKUP_SINGLE_FETCHED,
    //         result: body,
    //         entity_type,
    //         entity_oid,
    //     });
    // },
    // multi: (entity_oids, entity_type) => async (dispatch, getState) => {
    //     const { dictionary } = getState();
    //     const items = dictionary[entity_type] || [];

    //     const newIds = entity_oids.filter(
    //         (id) => !Object.prototype.hasOwnProperty.call(items, id)
    //     );
    //     entity_oids = [...newIds].join(",");

    //     if (isEmpty(entity_oids)) {
    //         return;
    //     }

    //     dispatch({ type: LOOKUP_FETCHING });
    //     const { body } = await apiClient.send("get", MULTI_URL, {
    //         entity_oids,
    //         entity_type,
    //     });
    //     dispatch({
    //         type: LOOKUP_MULTI_FETCHED,
    //         result: body,
    //         entity_type,
    //         entity_oids,
    //     });
    // },

    // multiByTag: (tag_type, tags) => async (dispatch) => {
    //     if (isEmpty(tags)) {
    //         return;
    //     }
    //     tags = [...tags].join(",");
    //     dispatch({ type: LOOKUP_FETCHING });
    //     const { body } = await apiClient.send("get", MULTI_TAG_URL, {
    //         tag_type,
    //         tags,
    //     });
    //     dispatch({
    //         type: LOOKUP_MULTI_TAG_FETCHED,
    //         result: body,
    //         tag_type,
    //         tags,
    //     });
    // },
});
export default actions;
