import Account from "@lumio/core/common/account";
import Client from "@lumio/core/common/apiClient";
import cdn from "@lumio/core/common/util/cdn";
import {isBrowser} from "@lumio/core/common/util/browserOrNode";

let apiClient = null;

if (!isBrowser) {
    const port = process.env.PORT || 3001;
    apiClient = new Client({baseUrl: `http://localhost:${port}/api`});
} else {
    apiClient = new Client({baseUrl: "/api"});
}

export const client = apiClient;
export const account = new Account(apiClient);
export const CDN = cdn();

