import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import makeStyles from "@material-ui/styles/makeStyles";

import { unstable_Box as Box } from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Tooltip from "@material-ui/core/Tooltip";

import { buildQuery } from "./ContentDetail";

const useStyle = makeStyles((theme) => ({
    title: {
        ...theme.typography.h6,
        color: "#FFFFFF",
        [theme.breakpoints.up("sm")]: {
            ...theme.typography.h5,
            color: "#FFFFFF",
        },
    },
    summaryButton: {
        marginLeft: 4,
        marginRight: 0,
        boxShadow: "none",
        [theme.breakpoints.up("sm")]: {
            marginLeft: 8,
            marginRight: 0,
        },
    },
    performanceButton: {
        marginLeft: 0,
        marginRight: 4,
        boxShadow: "none",
        [theme.breakpoints.up("sm")]: {
            marginLeft: 0,
            marginRight: 0,
        },
    },
}));

const ContentDetailDownloader = ({
    orgOid,
    baseQuery,
    performance,
    summary,
    reportRange,
    total,
}) => {
    const classes = useStyle();
    const [button, setButton] = useState(null);
    const [mod, setMod] = useState("log");

    const handleButton = (type) => (e) => {
        setMod(type);
        setButton(e.currentTarget);
    };

    const handleClose = () => {
        setButton(null);
    };

    const handleDownload = (out) => () => {
        const params = buildQuery(orgOid, baseQuery, mod, out, 1);
        const urlQuery = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join("&");
        window.open(`/api/query?${urlQuery}`, "_blank");
        handleClose();
    };

    let isAbleSummary =
        (summary === "1" || summary === 1) && total.TotalViews !== 0;
    let isAblePerformance =
        (performance === "1" || performance === 1) && total.TotalViews !== 0;

    if (reportRange.lastMonthIndex > 2) {
        isAblePerformance = false;
    }

    return (
        <Box display="flex" alignItems="center" justifyContent="start">
            <Button
                className={classes.performanceButton}
                color="primary"
                onClick={!isAblePerformance ? () => {} : handleButton("log")}
                variant="contained"
                disabled={!isAblePerformance}
            >
                Performance
            </Button>

            <Button
                className={classes.summaryButton}
                color="primary"
                onClick={!isAbleSummary ? () => {} : handleButton("summary")}
                variant="contained"
                disabled={!isAbleSummary}
            >
                Summary
            </Button>

            <Popper
                anchorEl={button}
                disablePortal
                open={!isEmpty(button)}
                transition
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        id="menu-list-grow"
                        style={{
                            transformOrigin:
                                placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    <MenuItem onClick={handleDownload("csv")}>
                                        CSV
                                    </MenuItem>
                                    <MenuItem onClick={handleDownload("xlsx")}>
                                        Excel
                                    </MenuItem>
                                    <MenuItem onClick={handleDownload("html")}>
                                        HTML
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
};

export default ContentDetailDownloader;
