import React from "react";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/styles/makeStyles";
import FooterLogo from "../../assets/img/lumioGray.svg";

const useStyles = makeStyles((theme) => ({
    footerWrapper: {
        width: "100%",
        height: 60,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bottom: 0,
        backgroundColor: theme.custom.baseBackgroundColor,
        borderTop: "1px solid",
        borderColor: "#e0e0e0",
        position: "absolute",
    },
    footerContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 10px",
        [theme.breakpoints.up("sm")]: {
            padding: "0 24px",
        },
    },
    footerNav: {
        margin: "0 -16px",
    },
    footerNavItem: {
        padding: "0 16px",
        color: theme.custom.colors.fontDefault,
    },
    footerLogo: {
        width: 64,
        height: 20,
    },
}));

const Footer = () => {
    const classes = useStyles();

    return (
        <footer className={classes.footerWrapper}>
            <Grid container style={{ maxWidth: 1400 }}>
                <Grid item xs={12}>
                    <div className={classes.footerContainer}>
                        <div className={classes.footerNav}>
                            <Link
                                href="https://help.lumio.id"
                                target="_blank"
                                rel="noopener"
                                variant="body2"
                                className={classes.footerNavItem}
                            >
                                Help
                            </Link>
                            <Link
                                href="https://www.lumio.id"
                                target="_blank"
                                rel="noopener"
                                variant="body2"
                                className={classes.footerNavItem}
                            >
                                About
                            </Link>
                        </div>
                        <a
                            href="https://www.lumio.id"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {/* <FooterLogo
                                className={`${classes.footerLogo} footer-logo`}
                            /> */}

                            <img
                                src={FooterLogo}
                                alt="React Logo"
                                className={classes.footerLogo}
                            />
                        </a>
                    </div>
                </Grid>
            </Grid>
        </footer>
    );
};

export default Footer;
