import React, { useState, useEffect } from "react";
import {Redirect, Route} from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import {authentication} from "../AuthenticationContext";

import Loading from "@lumio/components/Loading";

export default function AuthRouter({ component: Component, ...rest }) {
    const [isAuthenticated, setIsAuthenticated] = useState(authentication.isAuthenticated);
    const [authParams, setAuthParams] = useState(null);
    useEffect(() => {
        if (isEmpty(authParams)) {
            return;
        }
        authentication.setParams(authParams).then((newAuth) => {
            setIsAuthenticated(newAuth.isAuthenticated);
        }).catch((e) => {
            setIsAuthenticated(false);
        });
    }, [authParams]);
    return (
        <Route 
            {...rest}
            render={(props) => {
                const {match: {params}} = props;
                if (isEmpty(authParams)) {
                    setAuthParams(params);
                }
                if (isAuthenticated === true) {
                    return <Component {...props} />;
                }

                if (isAuthenticated === null) {
                    return <Loading />;
                }

                return (<Redirect
                    to={{
                        pathname: authentication.getUrlLogin(),
                        state: { from: props.location }
                    }}
                />);
            }
            }
        />
    );
}
