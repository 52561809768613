import React, { useState, useContext, useEffect } from "react";
import moment from "moment-timezone";
import isEmpty from "lodash/isEmpty";

import makeStyles from "@material-ui/styles/makeStyles";

import { unstable_Box as Box } from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Typography from "@material-ui/core/Typography";

import AssessmentIcon from "@material-ui/icons/Assessment";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import InventoryContext from "../../../components/InventoryContext";
import Search from "../Serach";
import ContentList from "./ContentList";
import FilterContext from "./FilterContext";
import MonthlyRangeSelector from "src/components/MonthlyRangeSelector";

const useStyle = makeStyles((theme) => ({
    root: {},
    rootHeader: {
        marginBottom: theme.spacing.unit * 2,
        display: "flex",
        position: "relative",
        zIndex: 1,
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            flexDirection: "unset",
            alignItems: "center",
            justifyContent: "space-between",
        },
    },
    rootActions: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "100%",
        marginTop: 8,
        [theme.breakpoints.up("sm")]: {
            marginTop: 0,
        },
    },
    reportButton: {
        boxShadow: "none",
        marginLeft: 8,
        [theme.breakpoints.up("sm")]: {
            marginLeft: 8,
        },
    },
    paper: {
        position: "relative",
        overflow: "hidden",
    },
    gridContainer: {
        padding: 8,
        overflowY: "scroll",
        height: "calc(100vh - 350px)",
        minHeight: 220,
        [theme.breakpoints.up("sm")]: {
            padding: 16,
            height: "auto",
            maxHeight: "calc(100vh - 340px)",
            minHeight: 200,
        },
    },
    iconButton: {
        marginRight: theme.spacing.unit * 1,
    },
    dividerTitle: {},
    boxSearch: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    search: {
        borderBottom: "none",
    },
    checboxContainer: {
        marginLeft: 0,
        marginRight: 0,
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    reportCheckboxColor: {
        color: "rgba(0,0,0,0.25)",
    },
    reportCheckboxChecked: {
        color: theme.custom.colors.primary,
    },
}));

const FORMAT_START = "YYYY-MM-DDT00:00:00Z";
const FORMAT_END = "YYYY-MM-DDT23:59:59Z";

const Contents = () => {
    const classes = useStyle();
    const inventory = useContext(InventoryContext);
    const {
        name,
        organization_oid,
        configs: { hideOrganization, summary = "1" },
    } = inventory;

    const [button, setButton] = useState(null);
    const [filter, setFilter] = useState("");
    const [mod, setMod] = useState("log");
    const [selectedMediaReport, setSelectedMediaReport] = useState([]);
    const [isSelectedAll, setIsSelectedAll] = useState(false);
    const [reportRange, setReportRange] = useState({});
    const [baseQuery, setBaseQuery] = useState({});

    const changeReportRange = (val) => {
        setReportRange(val);
    };

    const handleButton = (type) => (e) => {
        setMod(type);
        setButton(e.currentTarget);
    };

    const handleClose = () => {
        setButton(null);
    };

    const buildQuery = (query, mod, out, org, attach = 0) => {
        return {
            org: org,
            query: JSON.stringify(query),
            mod,
            out,
            attach,
        };
    };

    const handleChangeSelectAll = (e) => {
        setIsSelectedAll(e.target.checked);
    };

    const handleDownload = (out) => () => {
        const query = {
            ...baseQuery,
            medias: selectedMediaReport,
        };
        const params = buildQuery(query, mod, out, organization_oid.oid, 1);
        const urlQuery = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join("&");
        window.open(`/api/query?${urlQuery}`, "_blank");
        handleClose();
    };

    useEffect(() => {
        if (!isEmpty(reportRange)) {
            setBaseQuery({
                type: "media",
                from: reportRange.start.format(FORMAT_START), // from date
                to: reportRange.end.format(FORMAT_END), // end date
                tz: moment.tz.guess(), // timezone of user
                hide_organization:
                    hideOrganization === "1" || hideOrganization === true
                        ? "1"
                        : "0",
                campaign_title: name,
            });
        }
    }, [reportRange]);

    return (
        <>
            {!isEmpty(inventory) && (
                <div className={classes.root}>
                    <div className={classes.rootHeader}>
                        <Typography
                            className={classes.dividerTitle}
                            color="textSecondary"
                            variant="h6"
                        >
                            Contents
                        </Typography>

                        <div className={classes.rootActions}>
                            <div style={{ marginLeft: 8 }}>
                                <MonthlyRangeSelector
                                    onChange={changeReportRange}
                                />
                            </div>

                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.reportButton}
                                disabled={
                                    isEmpty(selectedMediaReport) ||
                                    summary !== "1"
                                }
                                onClick={handleButton("campaign_summary")}
                            >
                                <AssessmentIcon
                                    className={classes.iconButton}
                                />
                                Report
                            </Button>

                            <Popper
                                anchorEl={button}
                                disablePortal
                                open={!isEmpty(button)}
                                transition
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        id="menu-list-grow"
                                        style={{
                                            transformOrigin:
                                                placement === "bottom"
                                                    ? "right top"
                                                    : "right bottom",
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener
                                                onClickAway={handleClose}
                                            >
                                                <MenuList>
                                                    <MenuItem
                                                        onClick={handleDownload(
                                                            "csv"
                                                        )}
                                                    >
                                                        CSV
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={handleDownload(
                                                            "xlsx"
                                                        )}
                                                    >
                                                        Excel
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={handleDownload(
                                                            "html"
                                                        )}
                                                    >
                                                        HTML
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                    </div>
                    <Paper className={classes.paper} elevation={0}>
                        <Box
                            className={classes.boxSearch}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <FormControlLabel
                                className={classes.checboxContainer}
                                control={
                                    <Checkbox
                                        classes={{
                                            root: classes.reportCheckboxColor,
                                            checked:
                                                classes.reportCheckboxChecked,
                                        }}
                                        icon={<CheckBoxIcon />}
                                        checkedIcon={<CheckBoxIcon />}
                                        checked={isSelectedAll}
                                        onChange={handleChangeSelectAll}
                                        value="select-all"
                                        color="primary"
                                    />
                                }
                            />

                            <Search
                                className={classes.search}
                                onChange={setFilter}
                                placeholder="Search Content"
                            />
                        </Box>
                        <div className={classes.gridContainer}>
                            <FilterContext.Provider value={filter}>
                                <ContentList
                                    setSelectedMediaReport={
                                        setSelectedMediaReport
                                    }
                                    selectedMediaReport={selectedMediaReport}
                                    isSelectedAll={isSelectedAll}
                                />
                            </FilterContext.Provider>
                        </div>
                    </Paper>
                </div>
            )}
        </>
    );
};

export default Contents;
