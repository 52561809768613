import React, { useEffect, useState } from "react";

import makeStyles from "@material-ui/styles/makeStyles";
import LinearProgress from "@material-ui/core/LinearProgress";

import PauseIcon from "@material-ui/icons/Pause";
import IconButton from "@material-ui/core/IconButton";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";

import {
    ACTION_PLAYER_PLAY,
    ACTION_PLAYER_STOP,
    ACTION_PLAYER_PAUSE,
} from "./CamDetail";

const useStyle = makeStyles((theme) => ({
    controls: {
        width: "100%",
        position: "absolute",
        bottom: 0,
    },
    progressRoot: {
        height: 6,
    },
    progressColorPrimary: {
        backgroundColor: "transparent",
    },
    crontrolsAction: {
        width: "100%",
        display: "flex",
        alignItems: "center",
    },
    button: {
        color: "#FFFFFF",
    },
}));

const CamDetailControl = ({
    refreshImage,
    nextImage,
    prevImage,
    camConfig,
    setCamConfig,
}) => {
    const classes = useStyle();
    const [progress, setProgress] = useState(0);
    const [lapseIndex, setLapseIndex] = useState({ index: 0, action: "next" });
    let theInterval;

    useEffect(() => {
        if (typeof theInterval !== "undefined") {
            clearInterval(theInterval);
            theInterval = null;
        }

        if (camConfig.action === ACTION_PLAYER_STOP) {
            setProgress(0);
            setLapseIndex({ index: 0, action: "next" });
        }

        if (camConfig.action === ACTION_PLAYER_PLAY) {
            if (camConfig.type === "recent") {
                function loopCam() {
                    theInterval = setInterval(function () {
                        refreshImage();
                        clearInterval(theInterval);
                        loopCam();
                    }, 15000);
                }
                if (typeof theInterval !== "undefined") {
                    clearInterval(theInterval);
                    theInterval = null;
                }
                loopCam();
            } else {
                function loopCam() {
                    theInterval = setInterval(function () {
                        setLapseIndex((prev) => {
                            const newIndex = prev.index + 1;
                            if (newIndex === camConfig.loadedLapse) {
                                return { index: 0, action: "next" };
                            }
                            return { index: newIndex, action: "next" };
                        });
                        clearInterval(theInterval);
                        loopCam();
                    }, 1000);
                }
                loopCam();
            }
        }

        return () => {
            clearInterval(theInterval);
        };
    }, [camConfig]);

    useEffect(() => {
        if (camConfig.type !== "recent") {
            if (camConfig.loadedLapse === 0) {
                return;
            }
            setProgress(
                () => (lapseIndex.index / (camConfig.loadedLapse - 1)) * 100
            );
            if (lapseIndex.action === "next") {
                nextImage(lapseIndex.index);
            } else {
                prevImage(lapseIndex.index);
            }
        }
    }, [lapseIndex]);

    const handleClickPause = () => {
        setCamConfig({
            ...camConfig,
            action: ACTION_PLAYER_PAUSE,
        });
    };

    const handleClickPlay = () => {
        setCamConfig({
            ...camConfig,
            action: ACTION_PLAYER_PLAY,
        });
    };

    const handleClickPrevious = () => {
        setCamConfig({
            ...camConfig,
            action: ACTION_PLAYER_PAUSE,
        });
        setLapseIndex((prev) => {
            const newIndex = prev.index - 1;
            if (newIndex === -1) {
                return {
                    index: camConfig.loadedLapse - 1,
                    action: "previous",
                };
            }
            return { index: newIndex, action: "previous" };
        });
    };

    const handleClickNext = () => {
        setCamConfig({
            ...camConfig,
            action: ACTION_PLAYER_PAUSE,
        });
        setLapseIndex((prev) => {
            const newIndex = prev.index + 1;
            if (newIndex === camConfig.loadedLapse) {
                return { index: 0, action: "next" };
            }
            return { index: newIndex, action: "next" };
        });
    };

    return (
        <div
            className={classes.controls}
            style={{ display: camConfig.type === "recent" ? "none" : "block" }}
        >
            {camConfig.type !== "recent" && (
                <div className={classes.crontrolsAction}>
                    {camConfig.action === ACTION_PLAYER_PLAY ? (
                        <IconButton
                            className={classes.button}
                            aria-label="Pause"
                            onClick={handleClickPause}
                        >
                            <PauseIcon />
                        </IconButton>
                    ) : (
                        <IconButton
                            className={classes.button}
                            aria-label="Play"
                            onClick={handleClickPlay}
                        >
                            <PlayArrowIcon />
                        </IconButton>
                    )}

                    <IconButton
                        className={classes.button}
                        aria-label="Previous"
                        onClick={handleClickPrevious}
                    >
                        <SkipPreviousIcon />
                    </IconButton>
                    <IconButton
                        className={classes.button}
                        aria-label="Next"
                        onClick={handleClickNext}
                    >
                        <SkipNextIcon />
                    </IconButton>
                </div>
            )}
            <LinearProgress
                classes={{
                    root: classes.progressRoot,
                    colorPrimary: classes.progressColorPrimary,
                }}
                color="primary"
                variant="determinate"
                value={progress}
            />
        </div>
    );
};

export default CamDetailControl;
