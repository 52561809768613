import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import Footer from "src/components/Footer/Footer";

const style = makeStyles((theme) => ({
    root: {
        display: "flex !important",
        position: "relative",
        padding: "24px",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
    },
    container: {
        width: "100%",
        textAlign: "center",
    },
    title: {
        ...theme.typography.h6,
        [theme.breakpoints.up("sm")]: {
            ...theme.typography.h4,
        },
    },
    breakpoint: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
}));

const Expired = (props) => {
    const classes = style();
    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <Typography variant="h4" className={classes.title}>
                    This link you followed has expired
                </Typography>
                <br />
                <Typography variant="body1">
                    The link was set to expire after a certain amount of
                    time.&nbsp;
                    <br className={classes.breakpoint} />
                    Please contact the person who shared this link with you.
                </Typography>
            </div>
            <Footer />
        </div>
    );
};

export default Expired;
