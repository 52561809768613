import React from "react";
import { hydrate } from "react-dom";
import ThemeProvider from "./components/Theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import StyleContext from "isomorphic-style-loader/StyleContext";
import Favicon from "react-favicon";
import { BrowserRouter } from "react-router-dom";
import InventoryContext from "./components/InventoryContext";
import ConfigsContext from "./components/ConfigsContext";
import logo from "./assets/img/logo.png";

import App from "./App";

const insertCss = (...styles) => {
    const removeCss = styles.map(style => style._insertCss());
    return () => removeCss.forEach(dispose => dispose());
};

const root = document.getElementById("root");
const {inventory = {}, configs} = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;


hydrate(
    <StyleContext.Provider value={{ insertCss }}>
        <Favicon url={logo}/>
        <CssBaseline/>
        <ThemeProvider>
            <ConfigsContext.Provider value={configs}>
                <InventoryContext.Provider value={inventory}>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </InventoryContext.Provider>
            </ConfigsContext.Provider>
        </ThemeProvider>
    </StyleContext.Provider>,    
    root,
    ()=>{
        // We don't need the static css any more once we have launched our application.
        const ssStyles = document.getElementById("server-side-styles");
        ssStyles.parentNode.removeChild(ssStyles);
    }
);
