import React from "react";
import { hot, setConfig } from "react-hot-loader";
import withStyles from "isomorphic-style-loader/withStyles";
import { Route, Switch, withRouter } from "react-router-dom";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
const prod = process.env.NODE_ENV === "production";

import font from "typeface-roboto";
import base from "./assets/base.scss";
import Main from "./pages/Main";
import Expired from "./pages/Expired";
import AuthRouter from "./components/AuthRouter";
import RootRouterContext from "./components/RootRouterContext";

setConfig({
    ignoreSFC: true, // RHL will be __completely__ disabled for SFC
    pureRender: true, // RHL will not change render method
});

const App = ({ history, location, match }) => {
    const pathname = location.pathname;
    const paths = pathname.split("/");

    const logout = () => {
        return history.replace(
            `${["/login", ...paths.slice(2, paths.length - 2)].join("/")}`
        );
    };
    return (
        <RootRouterContext.Provider
            value={{ history, location, match, logout }}
        >
            <Switch>
                <AuthRouter
                    component={Main}
                    exact
                    path="/s/:id([a-f0-9]+)/:sig([a-f0-9]+)/:passSig([a-f0-9]+)/:nonce(\d+)"
                />
                <AuthRouter
                    component={Main}
                    exact
                    path="/s/:id([a-f0-9]+)/:sig([a-f0-9]+)"
                />
                <Route
                    component={Login}
                    exact
                    path="/login/:id([a-f0-9]+)/:sig([a-f0-9]+)"
                />
                <Route
                    component={Expired}
                    exact
                    path="/expired/:id([a-f0-9]+)"
                />
                <Route component={NotFound} />
            </Switch>
        </RootRouterContext.Provider>
    );
};

const CssWrapper = withStyles(font, base)(App);

export default !prod
    ? withRouter(hot(module)(CssWrapper))
    : withRouter(CssWrapper);
