import React, { useRef, useState } from "react";

import makeStyles from "@material-ui/styles/makeStyles";
import { unstable_Box as Box } from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import CloseIcon from "@material-ui/icons/Close";

import { client } from "src/common";
import CamDetailControl from "./CamDetailControl";

const useStyle = makeStyles((theme) => ({
    dialog: {
        maxWidth: "unset",
        [theme.breakpoints.up("md")]: {
            maxWidth: 670,
        },
    },
    dialogTitle: {
        padding: "16px 24px",
    },
    container: {
        marginBottom: theme.spacing.unit * 3,
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    closeIcon: {
        cursor: "pointer",
        "&:hover": {
            color: theme.custom.colors.primary,
        },
    },
    content: {
        padding: 0,
    },
    player: {
        width: "100%",
        position: "relative",
    },
    playerPoster: {
        width: "100%",
        position: "relative",
    },
    playerImage: {
        width: "100%",
    },
    playerCircularProgress: {
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.8)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: 0,
        zIndex: 1,
    },
    camType: {
        padding: "8px 4px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.up("sm")]: {
            padding: "16px 12px",
        },
    },
    camTypeContainer: {
        marginRight: 4,
        marginLeft: 4,
        width: "20%",
    },
    camTypeRatio: {
        width: "100%",
        paddingBottom: "56.25%",
        position: "relative",
    },
    camTypePoster: {
        width: "100%",
        height: "100%",
        backgroundColor: "#DDE3EA",
        position: "absolute",
        top: 0,
        left: 0,
    },
    camTypePosterImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: 4,
    },
    camTypeLabel: {
        ...theme.typography.caption,
        fontSize: "0.7rem",
        fontWeight: 500,
        color: theme.custom.colors.fontLight,
        padding: "4px 2px",
        [theme.breakpoints.up("sm")]: {
            ...theme.typography.subtitle2,
            color: theme.custom.colors.fontLight,
            padding: 4,
        },
    },
    camTypeLabelActive: {
        ...theme.typography.caption,
        fontSize: "0.7rem",
        fontWeight: 500,
        padding: "4px 2px",
        color: theme.custom.colors.primary,
        [theme.breakpoints.up("sm")]: {
            ...theme.typography.subtitle2,
            color: theme.custom.colors.primary,
            padding: 4,
        },
    },
}));

export const ACTION_PLAYER_PLAY = "play";
export const ACTION_PLAYER_STOP = "stop";
export const ACTION_PLAYER_PAUSE = "pause";

const CamDetail = ({ cam, getCamUrl, getTimestamp, onClose, ...props }) => {
    const classes = useStyle();

    const playerPosterRef = useRef(null);
    const playerPlaceholderRef = useRef(null);
    const playerPlaceholderImgRef = useRef(null);

    const [camConfig, setCamConfig] = useState({
        action: ACTION_PLAYER_PLAY,
        type: "recent",
        isRequest: false,
        loadedLapse: 0,
    });

    const camType = [
        { type: "recent", label: "RECENT" },
        { type: "1hr", label: "1 HOUR" },
        { type: "6hr", label: "6 HOURS" },
        { type: "1d", label: "1 DAY" },
        { type: "2d", label: "2 DAYS" },
    ];

    const handleOnClose = () => {
        setCamConfig({
            ...camConfig,
            action: ACTION_PLAYER_STOP,
        });
        onClose();
    };

    const handleClickCamType = (type, thumbnailUrl) => {
        if (camConfig.isRequest) {
            return;
        }

        playerPlaceholderImgRef.current.src = thumbnailUrl;
        playerPlaceholderRef.current.style.display = "block";
        playerPosterRef.current.style.display = "none";

        setCamConfig({
            action: ACTION_PLAYER_STOP,
            isRequest: true,
            type: type,
            loadedLapse: 0,
        });

        if (type === "recent") {
            const newImage = new Image();
            newImage.onload = function () {
                playerPosterRef.current.style.display = "block";
                playerPlaceholderRef.current.style.display = "none";
                setCamConfig({
                    action: ACTION_PLAYER_PLAY,
                    isRequest: false,
                    loadedLapse: 1,
                    type: type,
                });
            };
            newImage.src =
                getCamUrl(cam.username, cam.server) + "?" + getTimestamp();
            newImage.style["width"] = "100%";
            newImage.id = "cam-recent";
            playerPosterRef.current.innerHTML = "";
            playerPosterRef.current.appendChild(newImage);
        } else {
            client
                .send("get", "/proxy/cam/lapse", {
                    server: cam.server,
                    username: cam.username,
                    type: type,
                })
                .then(({ body: { Lapse } }) => {
                    if (Lapse) {
                        let tempLoadedLapse = 0;
                        Lapse.forEach((id, index) => {
                            const newImage = new Image();
                            newImage.onload = function () {
                                tempLoadedLapse++;
                                if (tempLoadedLapse === Lapse.length) {
                                    document.getElementById(
                                        "cam-snap-0"
                                    ).style.display = "block";
                                    playerPosterRef.current.style.display =
                                        "block";
                                    playerPlaceholderRef.current.style.display =
                                        "none";

                                    setCamConfig({
                                        action: ACTION_PLAYER_PLAY,
                                        isRequest: false,
                                        loadedLapse: tempLoadedLapse,
                                        type: type,
                                    });
                                }
                            };
                            newImage.src =
                                getCamUrl(
                                    cam.username,
                                    cam.server,
                                    "snap",
                                    id
                                ) +
                                "?" +
                                getTimestamp();
                            newImage.style["width"] = "100%";
                            newImage.id = "cam-snap-" + index;
                            newImage.style["display"] = "none";
                            if (index === 0) {
                                playerPosterRef.current.innerHTML = "";
                            }

                            playerPosterRef.current.appendChild(newImage);
                        });
                    } else {
                        const newImage = new Image();
                        newImage.onload = function () {
                            playerPosterRef.current.style.display = "block";
                            playerPlaceholderRef.current.style.display = "none";

                            setCamConfig({
                                action: ACTION_PLAYER_STOP,
                                isRequest: false,
                                loadedLapse: 1,
                                type: type,
                            });
                        };
                        newImage.src = thumbnailUrl;
                        newImage.style["width"] = "100%";
                        newImage.id = "cam-snap-0";
                        playerPosterRef.current.innerHTML = "";
                        playerPosterRef.current.appendChild(newImage);
                    }
                })
                .catch((e) => {
                    console.log("error");
                });
        }
    };

    const refreshImage = () => {
        document.getElementById("cam-recent").src =
            getCamUrl(cam.username, cam.server) + "?" + getTimestamp();
    };

    const nextImage = (index = null) => {
        if (index === 0) {
            document.getElementById(
                "cam-snap-" + (camConfig.loadedLapse - 1)
            ).style.display = "none";
        } else {
            document.getElementById("cam-snap-" + (index - 1)).style.display =
                "none";
        }
        document.getElementById("cam-snap-" + index).style.display = "block";
    };

    const prevImage = (index = null) => {
        if (index === camConfig.loadedLapse - 1) {
            document.getElementById("cam-snap-0").style.display = "none";
        } else {
            document.getElementById("cam-snap-" + (index + 1)).style.display =
                "none";
        }
        document.getElementById("cam-snap-" + index).style.display = "block";
    };

    return (
        <Dialog
            classes={{ paper: classes.dialog }}
            fullWidth
            maxWidth="md"
            onClose={handleOnClose}
            open
            {...props}
        >
            <DialogTitle className={classes.dialogTitle} disableTypography>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography variant="h6">
                        {"Cam " + cam.username + " View"}
                    </Typography>
                    <CloseIcon
                        className={classes.closeIcon}
                        onClick={handleOnClose}
                    />
                </Box>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <div className={classes.player}>
                    <div
                        className={classes.playerPoster}
                        ref={playerPlaceholderRef}
                        style={{ display: "none" }}
                    >
                        <img
                            className={classes.playerImage}
                            ref={playerPlaceholderImgRef}
                            src={""}
                        />
                        <div className={classes.playerCircularProgress}>
                            <CircularProgress />
                        </div>
                    </div>

                    <div className={classes.playerPoster} ref={playerPosterRef}>
                        <img
                            className={classes.playerImage}
                            id="cam-recent"
                            src={
                                getCamUrl(cam.username, cam.server) +
                                "?" +
                                getTimestamp()
                            }
                        />
                    </div>

                    <CamDetailControl
                        refreshImage={refreshImage}
                        nextImage={nextImage}
                        prevImage={prevImage}
                        camConfig={camConfig}
                        setCamConfig={setCamConfig}
                    />
                </div>

                <div className={classes.camType}>
                    {camType.map((item) => {
                        const thumbnailUrl =
                            item.type === "recent"
                                ? getCamUrl(cam.username, cam.server) +
                                  "?" +
                                  getTimestamp()
                                : getCamUrl(
                                      cam.username,
                                      cam.server,
                                      "past",
                                      item.type
                                  ) +
                                  "?" +
                                  getTimestamp();

                        const isLabelActive = camConfig.type === item.type;
                        return (
                            <div
                                className={classes.camTypeContainer}
                                key={item.type}
                                onClick={() =>
                                    handleClickCamType(item.type, thumbnailUrl)
                                }
                            >
                                <div className={classes.camTypeRatio}>
                                    <div className={classes.camTypePoster}>
                                        <img
                                            className={
                                                classes.camTypePosterImage
                                            }
                                            src={thumbnailUrl}
                                        />
                                    </div>
                                </div>
                                <Typography
                                    className={
                                        isLabelActive
                                            ? classes.camTypeLabelActive
                                            : classes.camTypeLabel
                                    }
                                    variant="subtitle2"
                                >
                                    {item.label}
                                </Typography>
                            </div>
                        );
                    })}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default withMobileDialog()(CamDetail);
