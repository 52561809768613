import React, { useContext } from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/styles/makeStyles";
import InventoryContext from "../InventoryContext";
import dateRangeFormat from "@lumio/core/common/util/dateRangeFormat";
import DateRange from "@material-ui/icons/DateRange";

const useStyle = makeStyles((theme) => ({
    smallTitle: {
        ...theme.typography.body2,
        color: theme.custom.colors.fontLighter,
        [theme.breakpoints.up("sm")]: {
            ...theme.typography.subtitle1,
            color: theme.custom.colors.fontLighter,
        },
    },
    subtitleDate: {
        ...theme.typography.body2,
        marginTop: theme.spacing.unit,
        color: theme.custom.colors.fontLighter,
        lineHeight: "initial",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
            ...theme.typography.subtitle1,
            color: theme.custom.colors.fontLighter,
        },
    },
    title: {
        ...theme.typography.h6,
        color: "#FFFFFF",
        [theme.breakpoints.up("sm")]: {
            ...theme.typography.h5,
            color: "#FFFFFF",
        },
    },
}));

const HeaderTitle = (props) => {
    const { name, start_at, end_at } = useContext(InventoryContext);
    const classes = useStyle();

    return (
        <>
            <Typography
                className={classes.smallTitle}
                color="inherit"
                variant="subtitle1"
            >
                Campaign
            </Typography>
            <Typography
                className={classes.title}
                color="inherit"
                style={{ marginTop: 8, marginBottom: 8 }}
                variant="h5"
                {...props}
            >
                {name}
            </Typography>
            <Typography
                className={classes.subtitleDate}
                color="inherit"
                variant="subtitle1"
            >
                <DateRange
                    style={{
                        marginRight: 8,
                    }}
                />
                {dateRangeFormat(start_at, end_at, false)}
            </Typography>
        </>
    );
};

export default HeaderTitle;
