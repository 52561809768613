import React, { useState, useEffect, useContext } from "react";
import isEmpty from "lodash/isEmpty";
import { Redirect } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import {
    Typography,
    Paper,
    Avatar,
    CircularProgress,
    InputAdornment,
    IconButton,
    Button,
    TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import dateRangeFormat from "@lumio/core/common/util/dateRangeFormat";

import { client, CDN } from "../common";
import InventoryContext from "../components/InventoryContext";
import { authentication } from "../components/AuthenticationContext";
import handleLogin from "../common/login";
import Footer from "src/components/Footer/Footer";

const loadInventory = async (inventory_id) => {
    const { body: inventory } = await client.send(
        "get",
        "/inventory/inventory/info",
        { inventory_id, _expand: "organization_oid" }
    );
    return inventory;
};

const style = makeStyles((theme) => ({
    root: {
        width: "100%",
        display: "flex !important",
        position: "relative",
        padding: "24px 0",
        minHeight: "100vh",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.custom.baseBackgroundColor,
    },
    container: {
        color: "#FFF",
        width: "100%",
        zIndex: 3,
        maxWidth: 400,
        minHeight: 200,
        padding: "64px 10px",
    },

    header: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: -theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },

    body: {
        padding: theme.spacing.unit * 2,
    },

    orgAvatar: {
        margin: `${theme.spacing.unit * 2}px 0 0`,
        boxShadow: theme.shadows[1],
        width: "100%",
        height: "auto",
        maxWidth: 80,
        maxHeight: 80,
        padding: `0 ${theme.spacing.unit}`,
        backgroundColor: theme.custom.colors.border,
        borderRadius: 4,
    },
    orgAvatarEmpty: {
        margin: `${theme.spacing.unit * 2}px 0 0`,
        boxShadow: theme.shadows[1],
        width: 80,
        height: 80,
        padding: `0 ${theme.spacing.unit}`,
        backgroundColor: theme.custom.colors.fontDefault,
        borderRadius: 4,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "2em",
        color: "#FFFFFF",
    },
    orgName: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 3,
        color: theme.custom.colors.fontDefault,
    },

    subtitle: {
        marginBottom: theme.spacing.unit * 2,
    },

    title: {
        marginTop: 10,
        color: theme.custom.colors.fontDefault,
    },

    subtitleContent: {
        color: theme.palette.grey[500],
        fontWeight: 500,
    },
    subtitleDate: {
        marginTop: theme.spacing.unit,
        color: theme.custom.colors.fontDefault,
    },

    password: {
        marginTop: 0,
    },

    button: {
        marginTop: theme.spacing.unit * 3,
        width: "100%",
        boxShadow: "none",
    },

    label: {
        color: theme.custom.colors.fontLight,
    },
}));

const Header = ({ org, classes, ...props }) => (
    <div {...props}>
        {isEmpty(org) && <CircularProgress />}
        {!isEmpty(org) && (
            <>
                {!isEmpty(org.picture) ? (
                    <Avatar
                        alt={org.name}
                        className={classes.orgAvatar}
                        src={CDN.url(org.picture, ["w200"])}
                    />
                ) : (
                    <div className={classes.orgAvatarEmpty}>
                        <i className="lumio-icon-organization" />
                    </div>
                )}
                <Typography className={classes.orgName} variant="h6">
                    {org.name}{" "}
                </Typography>
            </>
        )}
    </div>
);

const InventoryTitle = ({ inventory, classes }) => {
    const { name, start_at, end_at } = inventory;
    return (
        <>
            <Typography className={classes.label} variant="subtitle1">
                Campaign
            </Typography>
            <Typography className={classes.title} variant="h4">
                {name}
            </Typography>
            <div className={classes.subtitle}>
                <Typography
                    className={classes.subtitleDate}
                    variant="subtitle1"
                >
                    {dateRangeFormat(start_at, end_at)}
                </Typography>
            </div>
        </>
    );
};

const TogglePassword = ({ handleClickShowPassword, showPassword }) => (
    <InputAdornment position="end">
        <IconButton
            aria-label="Toggle password visibility"
            onClick={handleClickShowPassword}
        >
            {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
    </InputAdornment>
);

const Login = ({
    match: {
        params: { id, sig },
    },
}) => {
    const defaultInventory = useContext(InventoryContext);
    const classes = style();
    const [inventory, setInventory] = useState(defaultInventory);
    const [auth, setAuth] = useState(false);
    const [showPassword, togglePassword] = useState(false);
    const [org, setOrg] = useState(defaultInventory.organization_oid || null);
    const {
        configs: { hideOrganization },
    } = inventory;

    const handleClickShowPassword = () => {
        togglePassword((val) => !val);
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string().required("Password is required!"),
    });

    const onSubmit = async (data, { resetForm, setSubmitting, setErrors }) => {
        try {
            const { passwordSignature, nonce } = await handleLogin({
                ...data,
                inventory_id: id,
                sig,
            });
            authentication.authenticate(
                { id, sig, nonce, passSig: passwordSignature },
                () => {
                    setSubmitting(false);
                    setAuth(true);
                }
            );
        } catch (e) {
            if (e.status === 422) {
                const {
                    response: {
                        body: { message },
                    },
                } = e;
                setErrors(message);
            } else {
                console.error(e);
            }
            setSubmitting(false);
        }
    };

    useEffect(() => {
        if (isEmpty(inventory)) {
            loadInventory(id).then((data) => {
                setInventory(data);
            });
        }
    }, []);

    useEffect(() => {
        if (!isEmpty(inventory)) {
            setOrg(inventory.organization_oid);
        }
    }, [inventory]);

    if (auth) {
        const to = {
            pathname: authentication.getProtectedUrl(),
        };

        return <Redirect to={to} />;
    }
    return (
        <div className={classes.root}>
            <div className={classes.container}>
                {(hideOrganization === false ||
                    hideOrganization === "0" ||
                    typeof hideOrganization === "undefined") && (
                    <Header
                        classes={classes}
                        className={classes.header}
                        org={org}
                    />
                )}

                <Paper className={classes.body} elevation={1}>
                    <InventoryTitle classes={classes} inventory={inventory} />
                    <Formik
                        initialValues={{ password: "" }}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                    >
                        {({
                            isSubmitting,
                            errors,
                            handleChange,
                            handleSubmit,
                            values,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        className={classes.password}
                                        error={!isEmpty(errors.password)}
                                        fullWidth
                                        helperText={errors.password}
                                        id="pa1_ss2_wo3_rd4"
                                        autoComplete="off"
                                        InputProps={{
                                            endAdornment: (
                                                <TogglePassword
                                                    handleClickShowPassword={
                                                        handleClickShowPassword
                                                    }
                                                    showPassword={showPassword}
                                                />
                                            ),
                                        }}
                                        label="Password"
                                        margin="normal"
                                        name="password"
                                        onChange={handleChange}
                                        type={
                                            showPassword ? "text" : "password"
                                        }
                                        value={values.password}
                                    />

                                    <Button
                                        className={classes.button}
                                        color="primary"
                                        disabled={isSubmitting}
                                        type="submit"
                                        variant="contained"
                                    >
                                        {isSubmitting ? "Loading" : "Login"}
                                    </Button>
                                </form>
                            );
                        }}
                    </Formik>
                </Paper>
            </div>

            <Footer />
        </div>
    );
};

export default Login;
