import React, { useState, useContext, useEffect } from "react";
import isEmpty from "lodash/isEmpty";

import makeStyles from "@material-ui/styles/makeStyles";
import withStyles from "@material-ui/styles/withStyles";
import { unstable_Box as Box } from "@material-ui/core/Box";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import List from "./List";
import Search from "../Serach";
import Context from "../Context";
import DeviceInfo from "./DeviceInfo";

const useStyle = makeStyles((theme) => ({
    root: {},
    rootHeader: {
        marginTop: -16,
        padding: "16px 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        zIndex: 1,
        backgroundColor: theme.custom.baseBackgroundColor,
        position: "sticky",
        top: 172,
        [theme.breakpoints.up("sm")]: {
            top: 182,
        },
    },
    rootHeaderBox: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    paper: {
        position: "relative",
        overflow: "hidden",
    },
    content: {
        // height: 200,
    },
    gridList: {
        display: "flex",
        flexWrap: "wrap",
        overflow: "hidden",
    },
    gridContainer: {
        padding: 8,
        overflowY: "scroll",
        height: "calc(100vh - 310px)",
        minHeight: 260,
        [theme.breakpoints.up("sm")]: {
            padding: 16,
            height: "auto",
            maxHeight: "calc(100vh - 340px)",
            minHeight: 200,
        },
    },
    add: {
        position: "absolute",
        top: theme.spacing.unit * 1,
        right: theme.spacing.unit * 1,
    },
    dividerTitle: {
        marginRight: 6,
        color: theme.custom.colors.fontDefault,

        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    dividerTitleActive: {
        marginRight: 6,
        color: theme.custom.colors.primary,
        cursor: "pointer",
    },
}));

const StyledTooltip = withStyles({
    tooltipPlacementBottom: {
        margin: "8px 0",
    },
})(Tooltip);

const Devices = () => {
    const classes = useStyle();
    const { inventory } = useContext(Context);
    const [filter, setFilter] = useState("");
    const [selectedDevice, setSelectedDevice] = useState({});
    const [deviceNameTooltip, setDeviceNameTooltip] = useState(false);

    const handleTooltipClose = () => {
        setDeviceNameTooltip(false);
    };

    const handleTooltipOpen = () => {
        setDeviceNameTooltip(true);
    };

    return (
        <>
            {!isEmpty(inventory) && (
                <div className={classes.root}>
                    <div className={classes.rootHeader}>
                        <Box
                            display="flex"
                            alignItems="center"
                            className={classes.rootHeaderBox}
                        >
                            <Typography
                                className={
                                    isEmpty(selectedDevice)
                                        ? classes.dividerTitle
                                        : classes.dividerTitleActive
                                }
                                variant="h6"
                                onClick={
                                    isEmpty(selectedDevice)
                                        ? null
                                        : () => setSelectedDevice({})
                                }
                            >
                                Devices
                            </Typography>

                            {!isEmpty(selectedDevice) && (
                                <ClickAwayListener
                                    onClickAway={handleTooltipClose}
                                >
                                    <StyledTooltip
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                        onClose={handleTooltipClose}
                                        open={deviceNameTooltip}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={selectedDevice.name}
                                        placement="bottom-start"
                                    >
                                        <Typography
                                            className={classes.dividerTitle}
                                            variant="h6"
                                            onClick={handleTooltipOpen}
                                        >
                                            {" / " + selectedDevice.name}
                                        </Typography>
                                    </StyledTooltip>
                                </ClickAwayListener>
                            )}
                        </Box>
                    </div>

                    {isEmpty(selectedDevice) ? (
                        <Paper className={classes.paper} elevation={0}>
                            <Search onChange={setFilter} />
                            <div className={classes.gridContainer}>
                                <List
                                    filter={filter}
                                    setSelectedDevice={setSelectedDevice}
                                />
                            </div>
                        </Paper>
                    ) : (
                        <DeviceInfo selectedDevice={selectedDevice} />
                    )}
                </div>
            )}
        </>
    );
};

export default Devices;
