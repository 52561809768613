import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import Footer from "src/components/Footer/Footer";

const style = makeStyles((theme) => ({
    root: {
        display: "flex !important",
        position: "relative",
        padding: "24px",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
    },
    container: {
        width: "100%",
        textAlign: "center",
    },
    code: {
        marginBottom: 8,
    },
    title: {
        ...theme.typography.h6,
        [theme.breakpoints.up("sm")]: {
            ...theme.typography.h4,
        },
    },
    breakpoint: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
}));

const NotFound = (props) => {
    const classes = style();
    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <Typography variant="h3" className={classes.code}>
                    404
                </Typography>
                <Typography variant="h4" className={classes.title}>
                    Page not found
                </Typography>
                <br />
                <Typography variant="body1">
                    Oops! We can't seem to find the page you're looking for.
                </Typography>
            </div>
            <Footer />
        </div>
    );
};

export default NotFound;
