import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import replace from "lodash/replace";
import GoogleMapReact from "google-map-react";

import makeStyles from "@material-ui/styles/makeStyles";
import { unstable_Box as Box } from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CameraRollIcon from "@material-ui/icons/CameraRoll";
import Place from "@material-ui/icons/Place";
import RefreshIcon from "@material-ui/icons/Refresh";

import ConfigsContext from "../../../components/ConfigsContext";
import InventoryContext from "../../../components/InventoryContext";
import { client } from "src/common";
import CamDetail from "./CamDetail";
import { CoverDevice } from "./Device";
import GMapMarker from "./GMapMarker";

const useStyle = makeStyles((theme) => ({
    container: {
        marginBottom: theme.spacing.unit * 3,
        padding: 16,
    },
    paperCam: {},
    headerCam: {
        padding: "8px 16px",
    },
    camRefresh: {
        color: theme.custom.colors.primary,
    },
    containerInfo: {
        width: "100%",
        display: "flex",
    },
    containerInfoPicture: {
        width: "100%",
        border: "1px solid #e0e0e0",
        borderRadius: 4,
    },
    containerInfoDetail: {
        width: "50%",
    },
    deviceDetail: {
        marginBottom: 8,
    },
    containerInfoDetailLocation: {
        marginTop: 24,
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            height: "220px !important",
        },
    },
    picture: {
        paddingBottom: "75%",
    },
    camHeader: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    camListSingle: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    camList: {
        width: "100%",
        display: "inline-block",
    },
    cam: {
        float: "left",
        width: "50%",
        marginBottom: 0,
        position: "relative",
    },
    camRatio: {
        width: "100%",
        paddingBottom: "56.25%",
        position: "relative",
    },
    camPoster: {
        width: "100%",
        height: "100%",
        backgroundColor: "#DDE3EA",
        position: "absolute",
        top: 0,
        left: 0,
    },
    camImg: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    camInfo: {
        padding: 16,
        width: "100%",
        background: "#000000",
        background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)",
        color: "#FFFFFF",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "absolute",
        bottom: 0,
    },
    iconButton: {
        marginRight: 8,
    },
}));

const getTimestamp = () => {
    const timestamp = new Date().getTime();
    const timestampSecond = Math.floor(timestamp / 1000);
    return timestampSecond;
};

const DeviceInfo = ({ selectedDevice }) => {
    const classes = useStyle();
    const clientConfigs = useContext(ConfigsContext);
    const inventory = useContext(InventoryContext);
    const {
        configs: { CCTV },
    } = inventory;
    const [isCamLoaded, setIsCamLoaded] = useState(false);
    const [cam, setCam] = useState([]);
    const [camTimestamp, setCamTimestamp] = useState(getTimestamp());
    const [openCam, setOpenCam] = useState(null);

    const isCCTV = CCTV === "1" || CCTV === true;

    const getCamUrl = (username, server, type = "default", range = "") => {
        if (isEmpty(username)) {
            return false;
        }
        let url;
        switch (type) {
            case "past":
                url = "https://" + server + "/" + username + "/past/" + range;
                break;
            case "snap":
                url = "https://" + server + "/" + username + "/snap/" + range;
                break;
            default:
                url = "https://" + server + "/" + username;
                break;
        }
        return url;
    };

    const handleClickCamRefresh = () => {
        setCamTimestamp(getTimestamp());
    };

    const handleClickCamView = (item) => {
        setOpenCam(item);
    };

    const handleCloseCamView = useCallback(() => {
        setOpenCam(null);
    });

    let theInterval;

    useEffect(() => {
        client
            .send("get", "/organization/cam/list_for_device", {
                device_oid: selectedDevice.oid,
            })
            .then(({ body: { list } }) => {
                if (list) {
                    setCam([...list]);
                }
                setIsCamLoaded(true);
            })
            .catch((e) => {
                setIsCamLoaded("error");
            });
    }, []);

    useEffect(() => {
        function loopCam() {
            theInterval = setInterval(function () {
                setCamTimestamp(getTimestamp());
                clearInterval(theInterval);
                loopCam();
            }, 15000);
        }

        if (isEmpty(cam)) {
            if (typeof theInterval !== "undefined") {
                clearInterval(theInterval);
            }
        } else {
            loopCam();
        }

        return () => {
            clearInterval(theInterval);
        };
    }, [cam]);

    return (
        <div>
            {openCam && (
                <CamDetail
                    cam={openCam}
                    onClose={handleCloseCamView}
                    getCamUrl={getCamUrl}
                    getTimestamp={getTimestamp}
                />
            )}
            <Grid container spacing={16}>
                <Grid item xs={12} sm={isCCTV ? 6 : 12} md={isCCTV ? 4 : 12}>
                    <Paper className={classes.container} elevation={0}>
                        <Grid container spacing={16}>
                            <Grid
                                item
                                xs={12}
                                sm={isCCTV ? 12 : 6}
                                md={isCCTV ? 12 : 4}
                            >
                                <div className={classes.containerInfoPicture}>
                                    <CoverDevice
                                        item={selectedDevice}
                                        thumbnailObjectFitCover={false}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={isCCTV ? 12 : 6}
                                md={isCCTV ? 12 : 8}
                            >
                                <Typography
                                    variant="subtitle2"
                                    style={{ marginBottom: 4 }}
                                >
                                    Timezone
                                </Typography>
                                <Typography
                                    className={classes.deviceDetail}
                                    variant="subtitle1"
                                >
                                    <Box display="flex" alignItems="center">
                                        <AccessTimeIcon
                                            color={"inherit"}
                                            style={{ marginRight: 6 }}
                                        />
                                        <span className={classes.spanTitle}>
                                            {replace(
                                                selectedDevice.timezone,
                                                "_",
                                                " "
                                            )}
                                        </span>
                                    </Box>
                                </Typography>

                                <div
                                    className={
                                        classes.containerInfoDetailLocation
                                    }
                                    style={{
                                        height: isCCTV
                                            ? 240
                                            : "calc(100% - 80px)",
                                    }}
                                >
                                    {selectedDevice.latitude &&
                                    selectedDevice.longitude ? (
                                        <GoogleMapReact
                                            bootstrapURLKeys={{
                                                key: clientConfigs.googleAPIKey,
                                            }}
                                            defaultCenter={{
                                                lat: selectedDevice.latitude,
                                                lng: selectedDevice.longitude,
                                            }}
                                            defaultZoom={15}
                                            disableDefaultUI={true}
                                        >
                                            <GMapMarker
                                                lat={selectedDevice.latitude}
                                                lng={selectedDevice.longitude}
                                            >
                                                <Place />
                                            </GMapMarker>
                                        </GoogleMapReact>
                                    ) : (
                                        <div className={classes.noLocation}>
                                            <Typography
                                                color={"inherit"}
                                                variant="subtitle1"
                                            >{`No Location Information`}</Typography>
                                        </div>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                {isCCTV && (
                    <Grid item xs={12} sm={6} md={8}>
                        <Paper className={classes.paperCam} elevation={0}>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                className={classes.headerCam}
                            >
                                <Typography color={"inherit"} variant="h6">
                                    Cam
                                </Typography>

                                {!isEmpty(cam) && (
                                    <Button
                                        size="medium"
                                        className={classes.camRefresh}
                                        onClick={handleClickCamRefresh}
                                    >
                                        <RefreshIcon
                                            style={{ marginRight: 6 }}
                                        />
                                        Refresh
                                    </Button>
                                )}
                            </Box>

                            <Grid container spacing={0}>
                                {isCamLoaded ? (
                                    isEmpty(cam) ? (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            style={{
                                                width: "100%",
                                                marginTop: 32,
                                                marginBottom: 32,
                                            }}
                                        >
                                            <p
                                                style={{
                                                    textAlign: "center",
                                                }}
                                            >
                                                No Cam Information
                                            </p>
                                        </Box>
                                    ) : (
                                        <>
                                            {cam.map((item) => (
                                                <Grid
                                                    item
                                                    key={item.username}
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                >
                                                    <div
                                                        className={
                                                            classes.camRatio
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                classes.camPoster
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    getCamUrl(
                                                                        item.username,
                                                                        item.server
                                                                    ) +
                                                                    "?" +
                                                                    camTimestamp
                                                                }
                                                                className={
                                                                    classes.camImg
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            className={
                                                                classes.camInfo
                                                            }
                                                        >
                                                            <Typography
                                                                color={
                                                                    "inherit"
                                                                }
                                                                variant="h6"
                                                            >
                                                                {item.username}
                                                            </Typography>
                                                            <Button
                                                                size="medium"
                                                                color={
                                                                    "inherit"
                                                                }
                                                                onClick={() =>
                                                                    handleClickCamView(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                <CameraRollIcon
                                                                    className={
                                                                        classes.iconButton
                                                                    }
                                                                />
                                                                VIEW
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            ))}
                                        </>
                                    )
                                ) : (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{
                                            margin: "64px 0",
                                            width: "100%",
                                        }}
                                    >
                                        <CircularProgress
                                            className={classes.progress}
                                        />
                                    </Box>
                                )}
                            </Grid>
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default DeviceInfo;
