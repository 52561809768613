import React, { useContext, useState, useEffect } from "react";
import moment from "moment-timezone";
import isEmpty from "lodash/isEmpty";

import makeStyles from "@material-ui/styles/makeStyles";
import { unstable_Box as Box } from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import CloseIcon from "@material-ui/icons/Close";

import Media from "@lumio/components/grid/Media";
import Clip from "@lumio/components/grid/Clip";
import dateRangeFormat from "@lumio/core/common/util/dateRangeFormat";

import { client } from "src/common";
import InventoryContext from "src/components/InventoryContext";
import MonthlyRangeSelector from "src/components/MonthlyRangeSelector";
import ContentDetailDownloader from "./ContentDetailDownloader";

const useStyle = makeStyles((theme) => ({
    detail: {
        overflow: "hidden",
        height: 250,
        marginLeft: 0,
        ...theme.shape,
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing.unit * 2,
            height: "100%",
        },
    },
    title: {
        ...theme.typography.h4,
    },
    subtitle: {
        display: "flex",
        alignItems: "center",
        color: theme.custom.colors.borderDark,
    },
    contentNameTitle: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    spanTitle: {
        margin: `0 ${theme.spacing.unit}px`,
    },

    dialogTitle: {
        padding: "16px 24px",
    },
    dialogTitleName: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    closeIcon: {
        cursor: "pointer",
        "&:hover": {
            color: theme.custom.colors.primary,
        },
    },

    detailRight: {
        margin: `${theme.spacing.unit * 2}px 0 0 0 `,
        [theme.breakpoints.up("sm")]: {
            margin: `0 0 0 ${theme.spacing.unit * 2}px`,
        },
    },
    infoGridContainer: {
        marginBottom: 8,
        [theme.breakpoints.up("sm")]: {
            marginBottom: 24,
        },
    },
    rightSideContainer: {
        height: "100%",
    },
    labelTitle2: {
        ...theme.typography.body2,
        color: theme.custom.colors.fontLight,
        marginBottom: 2,
        [theme.breakpoints.up("sm")]: {
            ...theme.typography.subtitle1,
            marginBottom: 4,
            color: theme.custom.colors.fontLight,
        },
    },
    countValue: {
        ...theme.typography.h6,
        lineHeight: 1.6,
        [theme.breakpoints.up("sm")]: {
            ...theme.typography.h4,
            lineHeight: 1,
        },
    },
    mediaLinkUrl: {
        marginBottom: 16,
        display: "block",
        [theme.breakpoints.up("sm")]: {
            marginBottom: 0,
        },
    },
    contentDetailDownloaderContainer: {
        width: "100%",
        display: "flex",
        alignItems: "start",
        justifyContent: "start",
        flexDirection: "column",
        [theme.breakpoints.up("sm")]: {
            alignItems: "center",
            flexDirection: "initial",
        },
    },
    MonthlyRangeSelectorContainer: {
        marginBottom: 8,
        [theme.breakpoints.up("sm")]: { marginBottom: 0 },
    },
}));

export const buildQuery = (organization_oid, query, mod, out, attach = 0) => {
    return {
        org: organization_oid,
        query: JSON.stringify(query),
        mod,
        out,
        attach,
    };
};

const Count = ({
    orgOid,
    item,
    reportRange,
    loading,
    total,
    isHiddenCount,
    ...props
}) => {
    const classes = useStyle();

    function durationToReadableFormat(second) {
        const d = Math.floor(second / (3600 * 24));
        const dVal = d * (3600 * 24);
        const h = Math.floor((second - dVal) / 3600);
        const hVal = h * 3600;
        const m = Math.floor((second - dVal - hVal) / 60);
        const mVal = m * 60;
        const s = second - dVal - hVal - mVal;

        if (d !== 0) {
            return d + "d " + h + "h " + m + "m " + s + "s";
        } else if (h !== 0) {
            return h + "h " + m + "m " + s + "s";
        } else if (m !== 0) {
            return m + "m " + s + "s";
        } else {
            return s + "s";
        }
    }

    return (
        <>
            <Grid className={classes.rightSideContainer} container>
                <Grid container direction="row" justify="space-between">
                    <Grid
                        item
                        md={6}
                        sm={12}
                        xs={12}
                        className={classes.infoGridContainer}
                    >
                        <Typography
                            className={classes.labelTitle2}
                            variant="subtitle1"
                        >
                            Total Views
                        </Typography>
                        {loading ? (
                            <CircularProgress color="primary" size={30} />
                        ) : (
                            <Typography
                                variant="h4"
                                className={classes.countValue}
                            >
                                {isHiddenCount || total.TotalViews === 0
                                    ? "-"
                                    : total.TotalViews}
                            </Typography>
                        )}
                    </Grid>
                    <Grid
                        item
                        md={6}
                        sm={12}
                        xs={12}
                        className={classes.infoGridContainer}
                    >
                        <Typography
                            className={classes.labelTitle2}
                            variant="subtitle1"
                        >
                            Total Durations
                        </Typography>
                        {loading ? (
                            <CircularProgress color="primary" size={30} />
                        ) : (
                            <Typography
                                variant="h4"
                                className={classes.countValue}
                            >
                                {isHiddenCount || total.TotalDurations === 0
                                    ? "-"
                                    : durationToReadableFormat(
                                          total.TotalDurations
                                      )}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

const ContentDetail = ({ item, type, open, onClose, ...props }) => {
    const classes = useStyle();

    const FORMAT_START = "YYYY-MM-DDT00:00:00Z";
    const FORMAT_END = "YYYY-MM-DDT23:59:59Z";
    const { name, title } = item;

    const {
        start_at,
        end_at,
        organization_oid: { oid: orgOid },
        configs: { hideOrganization, performance = "1", summary = "1" },
    } = useContext(InventoryContext);

    const [reportRange, setReportRange] = useState({
        start: null,
        end: null,
        lastMonthIndex: null,
    });
    const [baseQuery, setBaseQuery] = useState({});

    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState({ TotalDurations: 0, TotalViews: 0 });
    const isHiddenCount =
        performance !== "1" &&
        performance !== 1 &&
        summary !== "1" &&
        summary !== 1;

    const changeReportRange = (val) => {
        setReportRange(val);
    };

    useEffect(() => {
        if (reportRange.start === null) {
            return;
        }

        if (isHiddenCount) {
            setLoading(false);
            return;
        }

        const FORMAT_START = "YYYY-MM-DDT00:00:00Z";
        const FORMAT_END = "YYYY-MM-DDT23:59:59Z";

        const countQuery = {
            entity_id: item._id,
            type: "media",
            from: moment(reportRange.start).format(FORMAT_START), // from date
            to: moment(reportRange.end).format(FORMAT_END), // end date
            tz: moment.tz.guess(), // timezone of user
        };

        client
            .send(
                "get",
                "/query",
                buildQuery(orgOid, countQuery, "summary", "json")
            )
            .then(
                ({
                    body: {
                        Summary: { TotalDurations, TotalViews },
                    },
                }) => {
                    setTotal({ TotalDurations, TotalViews });
                    setLoading(false);
                }
            )
            .catch((e) => {
                setTotal({ TotalDurations: 0, TotalViews: 0 });
                setLoading(false);
            });
    }, [reportRange, isHiddenCount]);

    useEffect(() => {
        if (!isEmpty(reportRange.start)) {
            setBaseQuery({
                entity_id: item._id,
                type: "media",
                from: reportRange.start.format(FORMAT_START), // from date
                to: reportRange.end.format(FORMAT_END), // end date
                tz: moment.tz.guess(), // timezone of user
                hide_organization:
                    hideOrganization === "1" || hideOrganization === true
                        ? "1"
                        : "0",
            });
        }
    }, [reportRange]);

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            onClose={onClose}
            open={open}
            {...props}
        >
            <DialogTitle className={classes.dialogTitle} disableTypography>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography
                        variant="h6"
                        className={classes.dialogTitleName}
                    >
                        {name || title}
                    </Typography>
                    <CloseIcon
                        className={classes.closeIcon}
                        onClick={onClose}
                    />
                </Box>
            </DialogTitle>

            <DialogContent>
                <Grid container>
                    <Grid item md={4} sm={6} xs={12}>
                        {type === "media" && (
                            <Media
                                activeHover={false}
                                item={item}
                                withInfo={false}
                            />
                        )}
                        {type === "clip" && (
                            <Clip
                                activeHover={false}
                                item={item}
                                withInfo={false}
                            />
                        )}
                    </Grid>
                    <Grid item md={8} sm={6} xs={12}>
                        <div className={classes.detailRight}>
                            <Grid
                                item
                                xs={12}
                                className={classes.infoGridContainer}
                            >
                                <Box
                                    display="flex"
                                    alignItems="start"
                                    justifyContent="start"
                                    flexDirection="column"
                                >
                                    <Typography
                                        className={classes.labelTitle2}
                                        variant="subtitle1"
                                    >{`Campaign Date`}</Typography>
                                    <Typography
                                        variant="subtitle1"
                                        style={{ fontWeight: 500 }}
                                    >
                                        {dateRangeFormat(
                                            start_at,
                                            end_at,
                                            false
                                        )}
                                    </Typography>
                                </Box>
                            </Grid>

                            {item.mime === "lumio/url" && (
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.infoGridContainer}
                                >
                                    <Typography
                                        className={classes.labelTitle2}
                                        variant="subtitle1"
                                    >
                                        URL
                                    </Typography>
                                    <Link
                                        href={item.alt_url}
                                        underline="always"
                                        target="_blank"
                                        className={classes.mediaLinkUrl}
                                    >
                                        {item.alt_url}{" "}
                                        <i className="lumio-icon-new_tab"></i>
                                    </Link>
                                </Grid>
                            )}

                            <Grid
                                item
                                xs={12}
                                className={classes.infoGridContainer}
                            >
                                <Box
                                    display="flex"
                                    alignItems="start"
                                    justifyContent="start"
                                    flexDirection="column"
                                >
                                    <Typography
                                        className={classes.labelTitle2}
                                        variant="subtitle1"
                                    >{`Report Date`}</Typography>
                                    <Typography
                                        variant="subtitle1"
                                        style={{ fontWeight: 500 }}
                                    >
                                        <MonthlyRangeSelector
                                            onChange={changeReportRange}
                                        />
                                    </Typography>
                                </Box>
                            </Grid>

                            <Count
                                orgOid={orgOid}
                                item={item}
                                reportRange={reportRange}
                                loading={loading}
                                total={total}
                                isHiddenCount={isHiddenCount}
                            />

                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    alignItems="start"
                                    justifyContent="start"
                                    flexDirection="column"
                                >
                                    <Typography
                                        className={classes.labelTitle2}
                                        variant="subtitle2"
                                    >{`Download Report`}</Typography>

                                    <Box
                                        className={
                                            classes.contentDetailDownloaderContainer
                                        }
                                    >
                                        <div
                                            className={
                                                classes.MonthlyRangeSelectorContainer
                                            }
                                        ></div>
                                        <ContentDetailDownloader
                                            orgOid={orgOid}
                                            baseQuery={baseQuery}
                                            performance={performance}
                                            summary={summary}
                                            reportRange={reportRange}
                                            total={total}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default withMobileDialog()(ContentDetail);
