import React from "react";
import PropTypes from "prop-types";


const GMapMarker = ({className, children, ...props}) => {
    return <div className={className} >{children}</div>;
};

GMapMarker.propTypes = {

};

GMapMarker.defaultProps = {

};


export default GMapMarker;
