import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import makeStyles from "@material-ui/styles/makeStyles";

import CDN from "@lumio/core/cdn";
import Container from "@lumio/components/grid/Container";
import CoverImage from "@lumio/components/grid/CoverImage";
import CoverIcon from "@lumio/components/grid/CoverIcon";
import InfoToolTip from "@lumio/components/grid/InfoTooltip";

const useStyleInfo = makeStyles((theme) => ({
    root: {
        position: "relative",
    },
}));

export const Info = ({ className, children, item, ...props }) => {
    const classes = useStyleInfo();
    const { name } = item;
    return (
        <div className={cs(className, classes.root)} {...props}>
            <InfoToolTip name={name} />
            {children}
        </div>
    );
};

Info.propTypes = {
    className: PropTypes.any,
    children: PropTypes.any,
    item: PropTypes.object.isRequired,
};

Info.defaultProps = {
    className: "",
    children: "",
};

const useStyleDevice = makeStyles((theme) => ({
    root: {},
    coverIcon: {
        backgroundColor: theme.custom.colors.border,
        color: theme.custom.colors.fontDefault,
        fontSize: "2.5rem",
    },
}));

export const CoverDevice = ({
    item,
    thumbnailObjectFitCover,
    ...restProps
}) => {
    const classes = useStyleDevice();
    let CoverProps;
    let CoverComponent;
    const { name, picture } = item;

    if (picture === null) {
        CoverProps = {
            name: "hardware",
            className: classes.coverIcon,
            iconProps: { style: { color: "#455A64", fontSize: "2.5rem" } },
        };
        CoverComponent = (covIconProps) => <CoverIcon {...covIconProps} />;
        return <CoverComponent {...CoverProps} {...restProps} />;
    } else {
        CoverProps = {
            fileUrl: CDN.url(picture, ["w800"]),
            title: name,
            width: 800,
            height: 400,
        };
        return (
            <CoverImage
                thumbnailObjectFitCover={thumbnailObjectFitCover}
                {...CoverProps}
                {...restProps}
            />
        );
    }
};

CoverDevice.propTypes = {
    item: PropTypes.object.isRequired,
};

CoverDevice.defaultProps = {
    item: {},
};

const Device = React.memo(
    ({
        item,
        showCctv,
        className,
        isSelected,
        InfoComponent,
        InfoProps,
        children,
        CoverProps,
        CoverComponent,
        withInfo,
        activeHover,
        ...props
    }) => {
        const classes = useStyleDevice();

        return (
            <Container
                activeHover={activeHover}
                className={cs(classes.root, className, {
                    selected: isSelected,
                })}
                InfoComponent={InfoComponent}
                InfoProps={{ ...InfoProps, item }}
                withInfo={withInfo}
                {...props}
            >
                <CoverComponent
                    item={item}
                    {...CoverProps}
                    thumbnailObjectFitCover
                />
                {children}
            </Container>
        );
    }
);

Device.propTypes = {
    item: PropTypes.object.isRequired,
    InfoComponent: PropTypes.elementType,
    InfoProps: PropTypes.object,
    CoverComponent: PropTypes.elementType,
    CoverProps: PropTypes.object,
    className: PropTypes.string,
    isSelected: PropTypes.bool,
    children: PropTypes.any,
    showCctv: PropTypes.bool,
    withInfo: PropTypes.bool,
    activeHover: PropTypes.bool,
};

Device.defaultProps = {
    InfoComponent: Info,
    CoverComponent: CoverDevice,
    InfoProps: {},
    CoverProps: {},
    className: "",
    isSelected: false,
    children: "",
    showCctv: true,
    withInfo: true,
    activeHover: true,
};

export default Device;
