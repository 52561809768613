import React, { useState, useEffect, useContext, useCallback } from "react";
import isEmpty from "lodash/isEmpty";

import makeStyles from "@material-ui/styles/makeStyles";

import Contents from "@lumio/components/grid/Contents";
import Loading from "@lumio/components/Loading";

import Context from "../Context";
import Device from "./Device";

const useStyle = makeStyles((theme) => ({
    root: {},
    cover: {
        backgroundColor: "#fafafa",
    },
}));

const List = ({ filter, setSelectedDevice }) => {
    const classes = useStyle();
    const { inventory, dictionary, actions } = useContext(Context);
    const {
        configs: { show_cctv = "1" },
    } = inventory;
    const { device = [] } = inventory.data || {};
    const { device: diviceDictionary = {} } = dictionary || { device: {} };
    const { lookupMulti } = actions;

    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(true);

    const onHandleSingleClick = useCallback((item) => {
        setSelectedDevice(item);
    });

    useEffect(() => {
        if (!isEmpty(device)) {
            lookupMulti(device, "device");
        } else {
            setDevices([]);
            setLoading(false);
        }
    }, [device]);

    useEffect(() => {
        if (!isEmpty(diviceDictionary)) {
            const newDevices = device.reduce((prev, oid) => {
                const deviceFromDic = diviceDictionary[oid];
                if (isEmpty(deviceFromDic)) {
                    return prev;
                }
                if (
                    deviceFromDic.name
                        .toUpperCase()
                        .indexOf(filter.toUpperCase()) === -1
                ) {
                    return prev;
                }
                return [...prev, deviceFromDic];
            }, []);
            setDevices(newDevices);
            setLoading(false);
        }
    }, [diviceDictionary, device, filter]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className={classes.root}>
            <Contents
                gridBreakPoint={{ xs: 6, sm: 4, md: 3, lg: 2 }}
                ContentComponent={Device}
                ContentComponentProps={{
                    showCctv: show_cctv === "1",
                }}
                getId={(item) => item.oid}
                list={devices}
                onHandleSingleClick={onHandleSingleClick}
                onIsSelected={() => {
                    false;
                }}
            />
        </div>
    );
};

export default List;
