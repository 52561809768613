import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import isEmpty from "lodash/isEmpty";

import makeStyles from "@material-ui/styles/makeStyles";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";

import CheckBoxIcon from "@material-ui/icons/CheckBox";

import Clip from "@lumio/components/grid/Clip";
import Media from "@lumio/components/grid/Media";

import ContentDetail from "./ContentDetail";

const useStyle = makeStyles((theme) => ({
    root: {
        userSelect: "none",
    },
    info: {
        paddingRight: 40,
    },
    mediaContainer: {
        position: "relative",
    },
    reportCheckbox: {
        position: "absolute",
        top: 0,
    },
    reportCheckboxColor: {
        color: "rgba(0,0,0,0.25)",
    },
    reportCheckboxChecked: {
        color: theme.custom.colors.primary,
    },
}));

const Contents = ({
    className,
    contentList,
    gridBreakPoint,
    setSelectedMediaReport,
    selectedMediaReport,
    isSelectedAll,
    ...props
}) => {
    const classes = useStyle();

    const [clickedItem, setClickedItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleOnClick = (item) => {
        setClickedItem(item);
    };

    const onClose = useCallback(() => {
        setDialogOpen(false);
        setTimeout(() => setClickedItem(null), 100);
    });

    const handleCheckboxChange = (e, _id) => {
        if (e.target.checked) {
            setSelectedMediaReport((prev) => [...prev, _id]);
        } else {
            setSelectedMediaReport((prev) => prev.filter((i) => i !== _id));
        }
    };

    useEffect(() => {
        if (isSelectedAll) {
            const allSelectedMedia = contentList.map(
                ({ type, data: item }) => item._id
            );
            setSelectedMediaReport(allSelectedMedia);
        } else {
            setSelectedMediaReport([]);
        }
    }, [isSelectedAll]);

    useEffect(() => {
        if (clickedItem) {
            setDialogOpen(true);
        }
    }, [clickedItem]);

    return (
        <>
            {!isEmpty(clickedItem) && (
                <ContentDetail
                    item={clickedItem}
                    onClose={onClose}
                    open={dialogOpen}
                    type="media"
                />
            )}

            <Grid
                container
                {...props}
                className={cs(classes.root, className)}
                spacing={16}
            >
                {contentList.map(({ type, data: item }) => (
                    <Grid item key={item._id} xs={6} sm={4} md={3} lg={2}>
                        {type === "clip" && (
                            <Clip
                                InfoProps={{ className: classes.info }}
                                item={item}
                                onClick={handleOnClick(type, item)}
                            />
                        )}
                        {type === "media" && (
                            <div className={classes.mediaContainer}>
                                <Media
                                    InfoProps={{ className: classes.info }}
                                    item={item}
                                    onClick={() => handleOnClick(item)}
                                    isSelected={selectedMediaReport.includes(
                                        item._id
                                    )}
                                    thumbnailObjectFitCover={true}
                                ></Media>
                                <Checkbox
                                    className={classes.reportCheckbox}
                                    classes={{
                                        root: classes.reportCheckboxColor,
                                        checked: classes.reportCheckboxChecked,
                                    }}
                                    color="primary"
                                    icon={<CheckBoxIcon />}
                                    checkedIcon={<CheckBoxIcon />}
                                    value={item._id}
                                    checked={
                                        selectedMediaReport.includes(
                                            item._id
                                        ) || isSelectedAll
                                    }
                                    onChange={(e) =>
                                        handleCheckboxChange(e, item._id)
                                    }
                                />
                            </div>
                        )}
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

Contents.propTypes = {
    gridBreakPoint: PropTypes.object,
};

Contents.defaultProps = {
    gridBreakPoint: {
        md: 3,
        sm: 4,
        xs: 6,
    },
};

export default Contents;
