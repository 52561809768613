import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import withStyles from "isomorphic-style-loader/withStyles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import RootRouterContext from "../RootRouterContext";
import InventoryContext from "../InventoryContext";

import muiIcons from "@mdi/font/scss/materialdesignicons.scss";

const useStyles = makeStyles((theme) => ({
    button: {
        padding: 0,
        minWidth: "auto",
        [theme.breakpoints.up("sm")]: {
            padding: "4px 8px",
            minWidth: 64,
        },
    },
    actionLabel: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block",
            marginLeft: 6,
        },
    },
}));

const ActionButton = () => {
    const classes = useStyles();
    const inventory = useContext(InventoryContext);
    const {
        configs: { hasPassword = "0" },
    } = inventory;
    const { logout } = useContext(RootRouterContext);
    if (hasPassword !== "1") {
        return "";
    }
    return (
        <Button
            size="small"
            color="inherit"
            className={classes.button}
            onClick={logout}
        >
            <ExitToAppIcon />
            <span className={classes.actionLabel}>Logout</span>
        </Button>
    );
};

export default withStyles(muiIcons)(ActionButton);
