import React, { useContext, useEffect, useState } from "react";
import moment from "moment-timezone";

import makeStyles from "@material-ui/styles/makeStyles";
import withStyles from "@material-ui/styles/withStyles";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import dateRangeFormat from "@lumio/core/common/util/dateRangeFormat";
import InventoryContext from "src/components/InventoryContext";

const BootstrapInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing.unit * 3,
        },
    },
    input: {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #2892FE",
        color: theme.custom.colors.primary,
        fontSize: 16,
        width: "auto",
        padding: "8px 26px 8px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            borderRadius: 4,
            borderColor: theme.custom.colors.primary,
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.up("sm")]: {},
        // color: theme.custom.colors.fontDefault,
    },
    formControl: {},
}));

export function generateRangeList(start, end) {
    const startDate = moment(start);
    const endDate = moment(end);
    const today = moment();
    const DATE_FORMAT = "YYYY-MM-DD";
    let result = [];

    while (startDate.isBefore(endDate) || startDate.isSame(endDate, "month")) {
        if (startDate.isAfter(today) && !startDate.isSame(today, "month")) {
            break;
        }

        result.push({
            start: startDate.startOf("month").format(DATE_FORMAT),
            end: today.isBefore(startDate.endOf("month").format(DATE_FORMAT))
                ? today.format(DATE_FORMAT)
                : startDate.endOf("month").format(DATE_FORMAT),
        });

        startDate.add(1, "month");
    }

    let formattedResult = [];
    result.forEach((range, index) => {
        const lastMonthIndex = result.length - (index + 1);
        if (lastMonthIndex > 11) {
            return;
        }
        formattedResult.push({
            lastMonthIndex: result.length - (index + 1),
            id: range.start + "/" + range.end,
            label: moment(range.start).format("MMMM YYYY"),
            ...range,
        });
    });

    return formattedResult.reverse();
}

const MonthlyRangeSelector = ({ onChange }) => {
    const classes = useStyles();

    const { start_at, end_at } = useContext(InventoryContext);

    const rangeList = generateRangeList(start_at, end_at);
    const [selectedRange, setSelectedRange] = useState(
        rangeList.length !== 0 ? rangeList[0].id : null
    );

    const handleChange = (event) => {
        setSelectedRange(event.target.value);
    };

    useEffect(() => {
        if (selectedRange) {
            const selecteditem = rangeList.find(
                (item) => item.id === selectedRange
            );
            const selectedRangeArr = selectedRange.split("/");

            if (typeof onChange === "function") {
                onChange({
                    start: moment(selectedRangeArr[0]),
                    end: moment(selectedRangeArr[1]),
                    lastMonthIndex: selecteditem.lastMonthIndex,
                });
            }
        }
    }, [selectedRange]);

    return (
        <div className={classes.container}>
            <FormControl id="mui-range-customized-select">
                <Select
                    value={selectedRange}
                    onChange={handleChange}
                    input={
                        <BootstrapInput
                            name="range"
                            id="range-customized-select"
                        />
                    }
                >
                    {rangeList.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default MonthlyRangeSelector;
