import { createContext } from "react";
import {client} from "../common";


const AuthenticationContext = createContext();
export default AuthenticationContext;
export const Provider = AuthenticationContext.Provider;
export const Consumer = AuthenticationContext.Consumer;

export const authentication = {
    params: {},
    
    isAuthenticated: null,
    authenticate(params = {}, cb) {
        this.params = params;
        this.isAuthenticated = true;
        setTimeout(cb, 100); // fake async
    },
    signout(cb) {
        this.isAuthenticated = false;
        setTimeout(cb, 100);
    },

    setParams: (params) => {
        return authentication.validateParams(params);
    },

    validateParams: async (params)=> {
        const {id, sig, passSig, nonce} = params;
        const url = `/auth/${id}/${sig}/${passSig}/${nonce}`;
        await client.send("get", url);
        authentication.isAuthenticated = true;
        authentication.params = params;
        return authentication;
    },

    getUrlLogin() {
        const {id, sig} = this.params;
        return `/login/${id}/${sig}`;
    },

    getProtectedUrl() {
        const {id, sig, passSig, nonce} = this.params;
        return `/s/${id}/${sig}/${passSig}/${nonce}`;
    }
};


