import React, { useContext, useEffect } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import dicAction from "@lumio/core/common/redux/dictionary/action";
import inventoryMediaAction from "../../lib/redux/inventory-media/action";
import Container from "@lumio/components/Container";
import { isBrowser } from "@lumio/core/common/util/browserOrNode";

import Header from "../../components/Header";
import { client } from "../../common";
import Contents from "./Contents";
import Context from "./Context";
import InventoryContext from "../../components/InventoryContext";
import Devices from "./Devices";
import makeStyles from "@material-ui/styles/makeStyles";

const useStyle = makeStyles((theme) => ({
    container: {
        maxWidth: 1400,
        padding: 0,
    },
}));

function getBooleanConfig(value) {
    if (value === undefined || isEmpty(value)) {
        return true;
    } else if (value === 1 || value === "1") {
        return true;
    } else if (value === true) {
        return true;
    }
    return false;
}

const App = ({ dictionary, inventoryMedia, actions, ...props }) => {
    const classes = useStyle();
    const inventory = useContext(InventoryContext);
    const { data = { device: [], content: [], tag: [] }, configs } =
        inventory || {};
    const { device, content, tag } = data;

    let isDevice = getBooleanConfig(configs.device);
    let isContent = getBooleanConfig(configs.content);

    const shouldRenderDevice = isDevice;
    const shouldRenderContent = isContent;

    useEffect(() => {
        if (isBrowser) {
            document.title = `Campaign - ${inventory.name}`;
        }
    }, []);
    return (
        <Context.Provider
            value={{ dictionary, inventory, inventoryMedia, actions }}
        >
            <HashRouter>
                <>
                    <Header
                        shouldRenderContent={shouldRenderContent}
                        shouldRenderDevice={shouldRenderDevice}
                    />
                    <Container className={classes.container}>
                        {shouldRenderDevice && shouldRenderContent && (
                            <Switch>
                                <Route component={Contents} path="/contents" />
                                <Route component={Devices} path="/devices" />
                            </Switch>
                        )}
                        {shouldRenderDevice && !shouldRenderContent && (
                            <Switch>
                                <Route component={Devices} path="/devices" />
                            </Switch>
                        )}
                        {!shouldRenderDevice && shouldRenderContent && (
                            <Switch>
                                <Route component={Contents} path="/contents" />
                            </Switch>
                        )}
                    </Container>
                </>
            </HashRouter>
        </Context.Provider>
    );
};

App.propTypes = {};

App.defaultProps = {};

const mapStateToProps = ({ dictionary, inventoryMedia }) => ({
    dictionary,
    inventoryMedia,
});

const { single, multi, multiByTag } = dicAction(client);
const { listMedia } = inventoryMediaAction(client);
const mapDispatchToProps = (dispatch) => ({
    actions: {
        // dictionary
        lookupSingle: (entity_oid, entity_type) =>
            dispatch(single(entity_oid, entity_type)),
        lookupMulti: (entity_oids, entity_type) =>
            dispatch(multi(entity_oids, entity_type)),
        lookupMultiByTag: (tag_type, tags) =>
            dispatch(multiByTag(tag_type, tags)),
        // inventoryMedia
        inventoryListMedia: (inventory_id) => dispatch(listMedia(inventory_id)),
    },
});

const Inventory = connect(mapStateToProps, mapDispatchToProps)(App);

export default Inventory;
