import React, { useState, useEffect } from "react";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import Clear from "@material-ui/icons/Clear";

import makeStyles from "@material-ui/styles/makeStyles";

const ClearButton = (props) => (
    <IconButton aria-label="Search" {...props}>
        <Clear />
    </IconButton>
);

const useStyle = makeStyles((theme) => ({
    root: {
        paddingRight: 2,
        paddingTop: 2,
        paddingBottom: 2,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    input: {
        flex: 1,
        padding: `0 ${theme.spacing.unit * 2}px`,
        lineHeight: "2em",
    },
    divider: {
        width: 1,
        height: 40,
        margin: 4,
    },
}));

const Search = ({ className, onChange, placeholder = "Search Device" }) => {
    const classes = useStyle();
    const [filter, setFilter] = useState("");

    const handleOnChangeInput = (e) => {
        setFilter(e.target.value);
    };
    const handleClear = () => {
        setFilter("");
    };

    useEffect(() => {
        onChange(filter);
    }, [filter]);

    return (
        <Grid className={`${classes.root} ${className}`} container>
            <InputBase
                className={classes.input}
                onChange={handleOnChangeInput}
                placeholder={placeholder}
                value={filter}
            />
            {filter !== "" && <ClearButton onClick={handleClear} />}
        </Grid>
    );
};

export default Search;
