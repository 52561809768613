import React from "react";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#2892fe",
        },
        text: {
            primary: "#455A64",
        },
    },
    typography: {
        useNextVariants: true,
    },
    custom: {
        applications: {
            grid: {
                folder: {
                    icon: {
                        color: "#757575",
                    },
                    color: "#212121",
                    backgroundColor: "#E0E0E0",
                },
                hover: {
                    backgroundColor: "#BBDDFF",
                },
            },
        },
        baseBackgroundColor: "#EFF1F9",
        colors: {
            primary: "#2892fe",
            primaryShade4: "#2483E4",
            primaryShade3: "#0A243F",
            primaryShade2: "#14497E",
            primaryShade1: "#1E6DBE",
            fontDark: "#212121",
            fontDefault: "#455A64",
            fontLight: "#607D8B",
            fontLighter: "#90A4AE",
            fontLightest: "#CFD8DC",
            border: "#DDE3EA",
            borderWhiteBg: "#E0E0E0",
            borderDark: "#BDBDBD",
        },
    },
});

function LumioThemes(props) {
    return <ThemeProvider theme={theme} {...props} />;
}

export default LumioThemes;
